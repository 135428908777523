<template>
  <v-dialog
    :value="openedValue"
    max-width="700px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card
      :loading="loading"
    >
      <v-card-title>
        <v-icon
          left
        >
          mdi-connection
        </v-icon>
        Création d'un nouveau provider
      </v-card-title>
      <v-form
        aria-autocomplete="none"
        @submit.prevent="add()"
      >
        <v-card-text>
          <v-row dense>
            <v-col
              cols="12"
              md="12"
              sm="12"
              xs="12"
            >
              <v-text-field
                v-model="form.provider.name"
                outlined
                hide-details
                dense
                label="Nom"
              />
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="12"
              xs="12"
            >
              <StoreSelector v-model="form.provider.storeId" />
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="12"
              xs="12"
            >
              <SourceSelector v-model="form.provider.source" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text
          v-if="form.provider.source === 'shopify'"
        >
          <v-row
            dense
            class="pa-2"
          >
            <v-col
              cols="12"
              md="12"
              sm="12"
              xs="12"
            >
              <v-text-field
                v-model="form.account.shop"
                :disabled="form.provider.source !== 'shopify'"
                autocomplete="off"
                outlined
                hide-details
                dense
                label="Shopify Shop"
                placeholder="<nom_du_shop>.myshopify.com"
              />
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="12"
              xs="12"
            >
              <v-text-field
                v-model="form.account.locationId"
                :disabled="form.provider.source !== 'shopify'"
                autocomplete="off"
                outlined
                hide-details
                dense
                label="Emplacement (Location id)"
                placeholder="<nom_du_shop>.myshopify.com"
              />
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="12"
              xs="12"
            >
              <v-text-field
                v-model="form.account.token"
                :disabled="form.provider.source !== 'shopify'"
                autocomplete="off"
                type="password"
                outlined
                hide-details
                dense
                label="Token"
                placeholder="shpat_************"
              />
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="12"
              xs="12"
            >
              <v-radio-group
                v-model="form.account.locale"
                label="Langue par défaut : "
                hide-details
                row
              >
                <v-radio
                  label="Français"
                  value="fr"
                />
                <v-radio
                  label="Anglais"
                  value="en"
                />
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close()"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>{{ $t('forms.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          raised
          color="green darken-1"
          class="white--text"
          @click="add()"
        >
          <v-icon left>
            mdi-check-outline
          </v-icon>{{ $t('forms.buttons.validate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SourceSelector from '@/components/base/ExternalCatalog/Input/SourceSelector.vue'
import StoreSelector from '@/components/base/StoreSelector.vue'

export default {
  name: 'ProviderCreationModal',
  components: { StoreSelector, SourceSelector },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading:false,
      form: {
        provider: {
          name: null,
          storeId: null,
          source: null,
        },
        account: {
          shop: null,
          token: null,
          locationId: null,
          locale: 'fr',
        },
      },
      message: {
        type: null,
        text: null,
        link: null,
      },
    }
  },
  
  computed: {
    openedValue: {
      get() {
        return this.opened
      },
      set(value) {
        this.$emit('update:opened', value)
      },
    },
  },
  methods: {
    close() {
      this.openedValue = false
      this.loading = false
      this.form = {
        provider: {
          name: null,
          storeId: null,
          source: null,
        },
        account: {
          shop: null,
          token: null,
          locationId: null,
          locale: 'fr',
        },
      }
    },
    async add() {
      this.loading = true
      let providerId = null
      
      try {
        const response = await this.$axios.post('/external_catalog/providers', this.form.provider)
        providerId = response.data['id']
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      }
      
      if (this.form.provider.source === 'shopify' && providerId !== null) {
        await this.addAccount(providerId, this.form.account)
      }
      
      this.close()
      this.$emit('submited')
    },
    async addAccount(providerId, account) {
      const payload = {
        ...account,
        providerId: providerId,
      }

      try {
        await this.$axios.post('/external_catalog/shopify_accounts', payload)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      }
    },
  },
}
</script>

<style scoped>

</style>
